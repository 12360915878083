import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";

class UserInfo extends React.Component {
  renderAnswer(format, v) {
    switch (format) {
      case "json":
        return (
          <ul>
            {Object.keys(v).map((key) => (
              <li key={key}>
                <label>{key}:</label> <strong>{v[key] ? "ja" : "nee"}</strong>
              </li>
            ))}
          </ul>
        );
      case "date":
        return v.toString();
      default:
        return <p>{v}</p>;
    }
  }

  render() {
    const { store, userId } = this.props;
    const user = store.users.get(userId);

    return (
      <div style={{ padding: "32px" }}>
        {user.answers.map((answer, index) => (
          <Question key={index}>
            <h3>{answer.question.title}</h3>
            {this.renderAnswer(answer.format, answer.value)}
          </Question>
        ))}
      </div>
    );
  }
}

const Question = styled.div`
  h3 {
    padding: 0 0 16px;
    margin: 0;
    font-size: 18px;
  }

  strong {
    font-weight: bold;
  }
  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      label {
        flex: 0 0 280px;
        margin-right: 16px;
      }
    }
  }

  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.gray02};
  margin-bottom: 16px;
`;

export default inject("store", "ui")(observer(UserInfo));
