import { Map } from "core-js";
import Expression from "./Expression";
import { decorate, observable } from "mobx";

class User {
  id;
  answers = [];
  geoAnswers = [];
  expressions = new Map();
  active = false;

  constructor(expressions, id) {
    this.id = id + "";
    Object.keys(expressions).forEach((key) => {
      this.expressions.set(key, new Expression(key, expressions[key], this));
    });
  }

  addAnswer(a) {
    this.answers.push(a);
  }

  addGeoAnswer(a) {
    this.geoAnswers.push(a);
  }

  getAnswers(questionId) {
    let answers = this.answers.filter((a) => a.questionId === questionId);
    return answers;
  }

  matchesFilters(filters) {
    for (let i = 0, n = filters.length; i < n; i++) {
      let filter = filters[i];
      if (filter.type === "user") {
        return filter.d.includes(this.id);
      } else if (filter.type === "answer") {
        let answers = this.getAnswers(filter.question.id);
        if (answers.length > 0) {
          for (let i = 0; i < answers.length; i++) {
            let answer = answers[i];
            if (!filter.matchesAnswer(answer.value)) {
              return false;
            }
          }
        } else {
          return false;
        }
      } else {
        let answers = this.geoAnswers.filter((ga) => ga.questionId === filter.question.id);

        let found = false;
        for (let i2 = 0; i2 < answers.length; i2++) {
          if (filter.type === "geo") {
            if (filter.matchesShape(answers[i2].value)) {
              found = true;
            }
          } else if (filter.type === "text") {
            if (filter.matchesText(answers[i2].flatDescription)) {
              found = true;
            }
          }
        }
        if (!found) return false;
      }
    }

    return true;
  }

  getGeoAnswers(geoQuestionId) {
    return this.geoAnswers.filter((ga) => {
      return ga.questionId === geoQuestionId;
    });
  }

  expression(key) {
    return this.expressions.get(key).value;
  }

  answerValue(questionId) {
    let answers = this.getAnswers(questionId);
    if (answers && answers.length) {
      return answers[0].valueString();
    }
    return "Geen antwoord";
  }
}

export default decorate(User, {
  active: observable
});
