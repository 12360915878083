export default class ConfigStore {
  store;
  config;

  mapStyles = {
    DEFAULT: {
      title: "Default",
      value: "mapbox://styles/refuel/cjdyiqa5l8xxr2sjorz2bhq5p"
    }
  };
  constructor(store) {
    this.store = store;
  }

  load(path) {
    return fetch(path)
      .then(response => response.json())
      .then(json => {
        this.config = json;
        this.processConfig();
        return true;
      });
  }

  processConfig() {
    if (this.config.colorKeys) {
      const theme = this.store.theme;
      Object.entries(this.config.colorKeys).forEach(kv => {
        theme.setKeyColor(kv[0], kv[1]);
      });
    }
    if (this.config.mapStyles) {
      this.mapStyles = this.config.mapStyles;
    }
  }

  getSortScheme(s) {
    let sortSchemes = this.config.sortSchemes;
    if (sortSchemes && sortSchemes[s]) {
      return sortSchemes[s];
    }
    return null;
  }
}
