import styled from "styled-components";

export const StyledPopup = styled.div`
  max-width: 200px;

  h4 {
    font-weight: bold;
  }

  ul.actions {
    display: flex;
    margin-top: 16px;

    li {
      margin-right: 8px;
    }
  }

  ul.label-list {
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${props => props.theme.gray02};
      padding-bottom: 4px;
      margin-bottom: $px;

      &:last-child {
        border-bottom: 0;
      }

      label {
        flex: 0 0 70%;
        font-weight: bold;
      }
    }
  }
`;

export const TopLeftControl = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 983;
`;
