import { decorate, computed } from "mobx";

class Expression {
  name;
  expression;
  user;

  constructor(name, expression, user) {
    this.name = name;
    this.expression = expression;
    this.user = user;
  }

  get value() {
    let result = this.expression.reduce((acc, exp) => {
      switch (exp[0]) {
        case "concat":
          for (let i = 1, n = exp.length; i < n; i++) {
            let answers = this.user.getAnswers(exp[i]);
            for (let i = 0; i < answers.length; i++) {
              let a = answers[i];
              if (a) {
                acc += a.value;
              } else {
                return acc;
              }
            }
          }
          return acc;
        case "substring":
          return acc.substring(exp[1], exp[2]);
        default:
          return acc;
      }
    }, "");

    return result;
  }
}

export default decorate(Expression, {
  value: computed
});
