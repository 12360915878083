import React from "react";
import { inject, observer } from "mobx-react";

//Chart libs
import { Group } from "@vx/group";
import { Bar } from "@vx/shape";
import { AxisBottom, AxisLeft } from "@vx/axis";
import { scaleLinear, scaleBand } from "@vx/scale";
import { withParentSize } from "@vx/responsive";
import { max } from "d3-array";
import { Spring } from "react-spring/renderprops";
import { truncateText } from "../../services/util";

class NewBarChart extends React.Component {
  guessDimensions(data) {
    let maxLength = data.reduce((acc, v) => {
      if (v.name.length > acc) return v.name.length;
      return acc;
    }, 0);

    if (maxLength < 20) {
      return [100, 30];
    }

    if (maxLength < 60) {
      return [120, 50];
    }

    return [250, 80];
  }

  render() {
    const { data, chart, parentWidth, ui, color } = this.props;
    //console.log(data);
    if (data.length && data[0].id === "novalue") return null;

    const [guessedMarginLeft, guessedBarHeight] = this.guessDimensions(data);
    const margin = {
      top: 5,
      left: this.props.marginLeft ? this.props.marginLeft : guessedMarginLeft,
      right: 20,
      bottom: 50,
    };
    const height = margin.top + margin.bottom + data.length * guessedBarHeight;
    const width = parentWidth;
    // accessors
    const y = (d) => d.name;
    const x = (d) => d.value;

    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const maxX = max(data.map(x));
    // // scales
    const xScale = scaleLinear({
      rangeRound: [0, xMax],
      domain: [0, maxX],
      nice: true,
    });
    const yScale = scaleBand({
      rangeRound: [yMax, 0],
      domain: data.map(y),
    });

    return (
      <svg width={width} height={height}>
        {data.map((d, i) => {
          const barWidth = xScale(x(d));
          const barHeight = yScale.bandwidth() - 2;

          return (
            <Spring key={`bar-spring-${i}`} to={{ newWidth: barWidth }}>
              {({ newWidth }) => (
                <Group key={`bar-${i}`} top={margin.top} left={margin.left}>
                  <Bar
                    x={0}
                    y={yScale(y(d))}
                    height={barHeight}
                    width={newWidth}
                    rx={4}
                    onClick={() => {
                      if (chart) {
                        chart.question.toggleFilter(d);
                      }
                    }}
                    fill={color ? color : d.color}
                  />
                  <text
                    fill="black"
                    width={40}
                    opacity={0.5}
                    textAnchor="start"
                    x={Math.min(newWidth + 5, width - 120)}
                    y={yScale(y(d)) + barHeight / 2}
                    dy=".33em"
                    fontSize={9}
                  >
                    {d.value}
                  </text>
                </Group>
              )}
            </Spring>
          );
        })}
        <Group key={"axi"} top={margin.top} left={margin.left}>
          <AxisLeft
            scale={yScale}
            stroke={ui.theme.theme.axisStroke}
            tickStroke={ui.theme.theme.axisTick}
            tickLabelProps={(value, index) => ({
              fill: ui.theme.theme.axisLabel,
              width: margin.left,
              fontSize: 12,
              fontFamily: "Arial",
              textAnchor: "end",
              scaleToFit: false,
              verticalAnchor: "middle",
            })}
            tickFormat={(val, i) => {
              if (this.props.maxTextLength) {
                return truncateText(val, this.props.maxTextLength);
              }
              return val;
            }}
          />
          <AxisBottom
            scale={xScale}
            top={yMax}
            stroke={ui.theme.theme.axisStroke}
            numTicks={maxX > 300 ? 4 : 6}
            tickStroke={ui.theme.theme.axisTick}
            tickFormat={(d) => parseInt(d, 10)}
            tickLabelProps={(value, index) => ({
              fill: ui.theme.theme.axisLabel,
              fontSize: 11,
              textAnchor: "middle",
            })}
          />
        </Group>
      </svg>
    );
  }
}

export default withParentSize(inject("ui")(observer(NewBarChart)));
