import React from "react";
import { MapContainer } from "../uikit/Frame";
import MapRenderer from "./map/MapRenderer";
import MapLegend from "./map/MapLegend";

export default class InfoMap extends React.Component {
  render() {
    const { store } = this.props;
    const mapView = store.activeChapter.activeMapView;
    if (!mapView) {
      return (
        <MapContainer>
          <p>Waiting for mapview</p>
        </MapContainer>
      );
    }
    return (
      <MapContainer>
        <MapRenderer store={store} mapView={mapView} />
        <MapLegend mapView={mapView} />
      </MapContainer>
    );
  }
}
