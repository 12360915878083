import React from "react";

import ChartItem from "./ChartItem";
import cn from "classnames";
import styled from "styled-components";

import ImageWithPopup from "./ImageWithPopup";
export default function Sidebar({ activeChapter, full }) {
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [ref, activeChapter]);

  return (
    <SidebarContainer
      ref={ref}
      className={cn({
        full
      })}
    >
      {activeChapter.sidebar.map((sb, index) => (
        <div
          className={cn({
            entry: true,
            "with-image": sb.img !== null
          })}
          key={index}
        >
          {sb.img !== null && <ImageWithPopup src={sb.img} />}
          <ChartItemContainer>
            <ChartItem title={sb.title} question={sb.question} />
          </ChartItemContainer>
        </div>
      ))}
    </SidebarContainer>
  );
}

const ChartItemContainer = styled.div`
  padding: 32px;
`;

export const SidebarContainer = styled.div`
  flex: 0 0 400px;
  z-index: 3;
  background-color: #fff;
  overflow-y: scroll;
  max-width: 100%;
  &.full {
    flex: 1 1 auto;
    background-color: #dfe2e7;
    padding: 8px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .entry {
      background-color: #fff;
      padding: 8px;
      margin-bottom: 8px;
      flex: 0 0 33%;
      &.with-image {
        flex: 0 0 100%;
        display: flex;
        align-items: stretch;

        .image-container {
          flex: 0 0 70%;
          height: auto;
        }
      }
    }
  }

  &.open {
    padding: 0;
  }

  .entry {
    &.with-image {
      ${ChartItemContainer} {
        flex: 1 1 auto;
      }
    }
  }
`;
