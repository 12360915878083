import { decorate, observable, action, computed, observe } from "mobx";
import MapViewLayer from "./MapViewLayer";
import SpatialFilter from "./SpatialFilter";
import MapViewSource from "./MapViewSource";
export const MAP_TYPE_NONE = "none";
export const MAP_TYPE_HEATMAP = "heatmap";

class MapView {
  store;
  title;
  //map settings
  zoom;
  center;
  layers;
  sources;

  popup = null;

  spatialFilter = null;
  showTextFilter = true;
  //external data
  _geojson;
  source;
  styles = ["DEFUALT"];
  activeStyle = "DEFAULT";
  loaded = false;

  constructor(store, conf) {
    this.store = store;
    this.title = conf.title;
    this.zoom = [conf.zoom];
    this.center = conf.center;
    this.layers = conf.layers.map(
      (lc) =>
        new MapViewLayer(
          this,
          lc,
          store.questionStore.getGeoQuestion(lc.geoQuestionId)
        )
    );
    if (conf.showTextFilter !== undefined)
      this.showTextFilter = conf.showTextFilter;
    if (conf.source) {
      this.source = conf.source;
    } else {
      this.loaded = true;
    }

    if (conf.sources) {
      this.sources = conf.sources.map((sc) => new MapViewSource(this, sc));
    }

    if (conf.styles) {
      this.styles = conf.styles;
    }
    if (conf.defaultStyle) {
      this.activeStyle = conf.defaultStyle;
    }

    observe(store.questionStore, "filters", (change) => {
      this.popup = null;
    });
  }

  showPopup(answer) {
    //console.log(answer);
    let point = answer.value.geometry.coordinates;
    if (answer.value.geometry.type === "LineString") {
      //console.log(point);
      point = point[0];
    }
    if (answer.value.geometry.type === "Polygon") {
      //console.log(point);
      point = point[0][0];
    }
    //console.log("B", point);

    this.popup = {
      coordinates: point,
      title: answer.value.properties.buttonname,
      description: answer.description,
      userId: answer.userId,
      files: answer.files,
    };
  }

  setCenter(obj) {
    this.center = [obj.lng, obj.lat];
  }

  setZoom(z) {
    this.zoom = [z];
  }

  get mapStyle() {
    let s = this.store.configStore.mapStyles[this.activeStyle];
    return s.value;
  }

  get mapStyleList() {
    return this.styles.map((s) => ({
      ...this.store.configStore.mapStyles[s],
      id: s,
    }));
  }

  setSpatialFilter(layer) {
    this.spatialFilter = new SpatialFilter(layer);
  }

  get hasSources() {
    return this.sources !== undefined;
  }

  get working() {
    if (this.spatialFilter) {
      return !this.spatialFilter.active;
    }
    return false;
  }
}

export default decorate(MapView, {
  zoom: observable,
  center: observable,
  popup: observable,
  loaded: observable,
  showPopup: action,
  spatialFilter: observable,
  working: computed,
  mapStyle: computed,
  mapStyleList: computed,
  activeStyle: observable,
});

// @computed
// get data() {
//   let result = [];
//   this.store.filteredUsers.forEach(user => {
//     result.push(...user.getGeoAnswers(this.geoQuestionId));
//   });
//   return result;
// }

// @computed
// get geojson() {
//   let result = _.cloneDeep(this._geojson);
//   let bla = 1;
//   result.features.forEach(f => {
//     f.properties.count = 0;
//     f.properties.index = bla;
//     bla++;
//   });

//   let withZip = 0;
//   let max = 0;
//   this.store.filteredUsers.forEach(user => {
//     let f = result.features.find(
//       f =>
//         f.properties[this.source.compare[0]] ===
//         user.expression(this.source.compare[1])
//     );
//     if (f) {
//       withZip++;
//       f.properties.count++;
//       if (f.properties.count > max) max = f.properties.count;
//     }
//   });

//   let testTotal = 0;
//   result.features.forEach(f => {
//     let p = f.properties.count / max;
//     f.properties.percent = Math.round(p * 100);
//     testTotal += f.properties.count;
//   });

//   return result;
// }

// loadData() {
//   fetch(this.source.url)
//     .then(response => response.json())
//     .then(json => {
//       this._geojson = json;
//       this.loaded = true;
//     });
// }
