import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { NavContainer } from "../uikit/Frame";
import { Nav, LeftNav, CenterNav, RightNav, NavItem } from "../uikit/Nav";
import { FilterList, FilterItem } from "../uikit/Filters";
import { truncateText } from "../services/util";
import AllQuestions from "./AllQuestions";
import Report from "./Report";
import UserList from "./UserList";
import Info from "./Info";
class MainNav extends React.Component {
  state = {
    showAll: false
  };

  actionToggleShowAll = (e) => {
    if (!this.state.showAll) {
      this.setState({ showAll: true }, () => {
        this.props.ui.content = <AllQuestions />;
      });
    } else {
      this.setState({ showAll: false }, () => {
        this.props.ui.content = null;
      });
    }
  };

  render() {
    const { store, ui } = this.props;
    return (
      <NavContainer>
        <Nav>
          <LeftNav>
            <RealLogo src="/conf/landvancuijk/logo-gemeente-rood.png" />
            <Logo
              href="#report"
              onClick={(e) => {
                e.preventDefault();
                ui.modal = {
                  title: "Respondenten",
                  children: <Report />
                };
              }}
            >
              <label>Respondenten: </label> {store.loading ? "" : store.users.size}
            </Logo>
          </LeftNav>
          <CenterNav>
            <NavItem>
              <label>filters:</label>
              <FilterList>
                {store.questionStore.allFilters.map((f, index) => (
                  <FilterItem
                    key={index}
                    onToggle={() => {
                      f.toggle();
                    }}
                    onClose={() => {
                      f.remove();
                    }}
                    className={f.active ? "active" : "in-active"}
                  >
                    {truncateText(f.title, 20)}
                  </FilterItem>
                ))}
                {store.questionStore.allGeoFilters.map((f, index) => (
                  <FilterItem
                    key={`g${index}`}
                    onToggle={() => {
                      f.toggle();
                    }}
                    onClose={() => {
                      f.remove();
                    }}
                    className={f.active ? "active" : "in-active"}
                  >
                    <span style={{ borderColor: f.question.color }} />
                    {truncateText(f.title, 20)}
                  </FilterItem>
                ))}
                {store.activeUser !== null && (
                  <FilterItem
                    key="userfilter"
                    className={store.userFilterActive ? "active" : "in-active"}
                    onToggle={() => {
                      store.userFilterActive = !store.userFilterActive;
                    }}
                    onClose={() => {
                      store.userFilterActive = true;
                      store.activeUser.active = false;
                    }}
                  >
                    User {store.activeUser.id}
                  </FilterItem>
                )}
              </FilterList>
            </NavItem>
          </CenterNav>
          <RightNav>
            <button
              onClick={(e) => {
                e.preventDefault();
                ui.modal = {
                  title: "Informatie",
                  children: <Info />
                };
              }}
            >
              Info
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                ui.modal = {
                  title: "Respondenten",
                  children: <UserList />
                };
              }}
            >
              Gebruikers filter
            </button>
            <button onClick={this.actionToggleShowAll}>{this.state.showAll ? "Sluiten" : "Alle vragen"}</button>
          </RightNav>
        </Nav>
      </NavContainer>
    );
  }
}

const RealLogo = styled.div`
  background: #fff url("${(props) => props.src}") no-repeat center center;
  background-size: contain;
  flex: 0 0 80px;
`;

const Logo = styled.a`
  flex: 0 0 160px;
  display: flex;
  align-items: center;
  font-weight: lighter;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  transition: color 300ms ease-in-out;
  padding: 0 32px;
  &:hover {
    color: #fff;
  }

  label {
    color: ${(props) => props.theme.gray04};
    margin-right: 10px;
  }

  &:hover {
    background-color: #383f46;
  }
`;

export default inject("ui")(observer(MainNav));
