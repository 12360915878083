import styled from "styled-components";

export const Button = styled.button`
  border: 0;
  background-color: ${props => props.theme.gray05};
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;

  &.primary {
    background-color: ${props => props.theme.mainColor};
  }
`;
