import React from "react";
export const TemperatureIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.8 95.1" {...props}>
    <g data-name="Layer 1">
      <path
        d="M53.1,69.1V12.4a10,10,0,0,0-20,0V69.1a15.94,15.94,0,0,0,10,28.4,16,16,0,0,0,10-28.4Zm-10,25a12.51,12.51,0,0,1-6.5-23.2V12.5a6.6,6.6,0,1,1,13.2,0V70.9a12.52,12.52,0,0,1-6.7,23.2Z"
        transform="translate(-27.1 -2.4)"
      />
      <path
        d="M46.2,73V50.1a3.1,3.1,0,0,0-6.2,0V73a9.1,9.1,0,1,0,6.2,0Z"
        transform="translate(-27.1 -2.4)"
      />
      <rect x="29.5" y="10.2" width="16.3" height="3.4" />
      <rect x="29.5" y="21.5" width="7.7" height="3.4" />
      <rect x="29.5" y="32.9" width="16.3" height="3.4" />
      <rect x="29.5" y="44.2" width="7.7" height="3.4" />
      <rect x="29.5" y="55.5" width="16.3" height="3.4" />
    </g>
  </svg>
);

export const HideIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 48.3">
    <path
      d="M80,50S68.6,71.9,50,71.9a25.11,25.11,0,0,1-12.8-3.6l4.9-4.9A15.06,15.06,0,0,0,50,65.5,15.62,15.62,0,0,0,65.6,49.9,16.34,16.34,0,0,0,63.5,42l5.8-5.8A59.38,59.38,0,0,1,80,50ZM40.7,50a8.66,8.66,0,0,0,.6,3.2L53.2,41.3a8.66,8.66,0,0,0-3.2-.6A9.35,9.35,0,0,0,40.7,50Zm-6.3,0A15.62,15.62,0,0,1,50,34.4a16.34,16.34,0,0,1,7.9,2.1l4.9-4.9A25.83,25.83,0,0,0,50,28c-18.6.1-30,22-30,22A55.22,55.22,0,0,0,30.8,63.6l5.8-5.8A14.77,14.77,0,0,1,34.4,50Zm24.9,0a8.66,8.66,0,0,0-.6-3.2L46.8,58.7a8.66,8.66,0,0,0,3.2.6A9.35,9.35,0,0,0,59.3,50ZM71.4,25.8,25.8,71.4l2.7,2.7L74.1,28.5Z"
      transform="translate(-20 -25.8)"
    />
  </svg>
);

export const ShowIcon = props => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 48.3">
    <path
      d="M30,2.2c-18.6,0-30,22-30,22c2.9,5.1,6.5,9.7,10.8,13.6l6.4,4.7c3.9,2.3,8.3,3.6,12.8,3.6c18.6,0,30-21.9,30-21.9
	C57.1,19.1,46.8,2.2,30,2.2z M30,39.7c-9.3,0-15.6-6.9-15.6-15.5c0-8.6,7-15.6,15.6-15.6c7.9,0,15.6,7.4,15.6,15.5
	C45.6,32.7,38.6,39.7,30,39.7z"
    />
    <circle cx="30" cy="24.1" r="9.3" />
  </svg>
);

export const XIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.73 12.73">
    <polygon points="12.73 1.41 11.31 0 6.36 4.95 1.41 0 0 1.41 4.95 6.36 0 11.31 1.41 12.73 6.36 7.78 11.31 12.73 12.73 11.31 7.78 6.36 12.73 1.41" />
  </svg>
);

export const PolyIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.7 59.7">
    <path
      d="M54.7,25a4.06,4.06,0,0,0-1.9.4L34.5,7.5a4.81,4.81,0,0,0,.7-2.4,5.2,5.2,0,0,0-10.4,0,5.45,5.45,0,0,0,.5,2.2L5.9,26.3c-.2,0-.4-.1-.6-.1a5,5,0,1,0,0,10,5.45,5.45,0,0,0,2.2-.5L25.2,53a5.84,5.84,0,0,0-.4,1.8,5.2,5.2,0,0,0,10.4,0,5.37,5.37,0,0,0-.5-2.1L53.1,34.6a7.72,7.72,0,0,0,1.5.2,5.06,5.06,0,0,0,5.2-5A4.88,4.88,0,0,0,54.7,25ZM27,9.2a5.07,5.07,0,0,0,3,.9,5.38,5.38,0,0,0,2.8-.8L50.7,26.9A5,5,0,0,0,49.5,30a4.76,4.76,0,0,0,1.4,3.4L33.1,50.9a5.4,5.4,0,0,0-6.4.1L9.5,34.1a4.76,4.76,0,0,0-1-6.8Z"
      transform="translate(-0.1 -0.1)"
    />
  </svg>
);
