import { decorate, computed, observable } from "mobx";
import MapView from "./MapView";

class Chapter {
  store;
  key = "";
  title = "";
  sidebar = [];
  mapViews = [];
  activeMapView = null;

  openQuestionIds = null;

  constructor(store, conf) {
    this.store = store;
    this.title = conf.title;
    this.generateKey(this.title);
    if (conf.sidebar) {
      this.sidebar = conf.sidebar.map(
        (item, n) => new SidebarItem(n, store, item)
      );
    }
    if (conf.map) {
      this.mapViews = conf.map.views.map((conf) => new MapView(store, conf));
      this.activeMapView = this.mapViews[0];
    }
    if (conf.open) {
      this.openQuestionIds = conf.open;
    }
  }

  setMapView(index) {
    if (index >= 0 && index < this.mapViews.length) {
      this.activeMapView = this.mapViews[index];
    }
  }

  get hasMap() {
    return this.mapViews.length > 0;
  }

  get hasOpen() {
    return this.openQuestionIds !== null;
  }

  generateKey(s) {
    this.key = s.toLowerCase().replace(/\s/g, "-");
  }
}

export default decorate(Chapter, {
  activeMapView: observable,
  hasMap: computed,
});

class SidebarItem {
  store;
  index = 0;
  title = "";
  question;
  img = null;

  constructor(index, store, conf) {
    //console.log("BLAAA", index, conf);
    this.index = index;
    this.store = store;
    this.question = store.questionStore.getQuestion(conf.questionId);
    //console.log(conf.questionId, this.question);
    this.title = conf.title ? conf.title : this.question.title;
    if (conf.img) {
      this.img = `/conf/${this.store.path}/media/${conf.img}`;
    }
  }
}
