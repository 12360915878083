import { decorate, observable, action } from "mobx";
import { fetchData } from "../services/Api";

class MapViewSource {
  mapView;
  type;
  title = null;
  src;
  loaded = false;
  loading = false;
  data = null;
  visible = true;

  //paint
  linePaint;
  fillPaint;

  constructor(mapView, conf) {
    this.mapView = mapView;
    this.type = conf.type;
    this.title = conf.title;
    this.src = conf.src;

    this.linePaint = conf.linePaint;
    this.fillPaint = conf.fillPaint;

    if (conf.show !== undefined) this.visible = conf.show;
  }

  load() {
    this.loading = true;
    let path = `./conf/${this.mapView.store.path}/${this.src}`;
    fetchData(path).then(
      action(r => {
        this.data = r;
        this.loading = false;
        this.loaded = true;
      })
    );
  }
}

export default decorate(MapViewSource, {
  loaded: observable,
  loading: observable,
  visible: observable
});
