import { decorate, computed } from "mobx";
import { Map } from "core-js";
import Question from "./Question";
import GeoQuestion from "./GeoQuestion";

class QuestionStore {
  store;
  questions = new Map();
  geoQuestions = new Map();

  constructor(store) {
    this.store = store;
  }

  get filters() {
    let result = [];
    this.questions.forEach((q) => {
      result.push(...q.activeFilters);
    });
    this.geoQuestions.forEach((q) => {
      result.push(...q.activeFilters);
    });
    return result;
  }

  get allFilters() {
    let result = [];
    this.questions.forEach((q) => {
      result.push(...q.filters);
    });
    return result;
  }

  get allGeoFilters() {
    let result = [];
    this.geoQuestions.forEach((q) => {
      result.push(...q.filters);
    });
    return result;
  }

  get geoFilters() {
    let result = [];
    this.geoQuestions.forEach((q) => {
      result.push(...q.activeFilters);
    });
    return result;
  }

  get textFilters() {
    return this.geoFilters.filter((gq) => gq.type === "text");
  }

  get filterableQuestions() {
    let result = [];
    this.questions.forEach((q) => {
      if (q.filterable) {
        result.push(q);
      }
    });
    return result;
  }

  get unfilterableQuestions() {
    let result = [];
    this.questions.forEach((q) => {
      if (!q.filterable) {
        result.push(q);
      }
    });
    return result;
  }

  getQuestion(id) {
    return this.questions.get(id);
  }

  getGeoQuestion(id) {
    return this.geoQuestions.get(id);
  }

  getQuestionByKey(key) {
    for (let [, v] of this.questions) {
      if (v.matchesKey(key)) {
        return v;
      }
    }
    return null;
  }

  getGeoQuestionByKey(key) {
    let question = null;
    this.geoQuestions.forEach((q) => {
      if (q.key === key) {
        question = q;
      }
    });
    return question;
  }

  load(path) {
    return fetch(path)
      .then((response) => response.json())
      .then((json) => {
        Object.entries(json.questions).forEach((q) => {
          //console.log("set", q);
          this.questions.set(q[1].id, new Question(q[0], q[1], this.store));
        });

        //console.log("LOADED QUESTIONS", this.questions.size);
        Object.entries(json.geoQuestions).forEach((q) => {
          this.geoQuestions.set(
            q[1].id,
            new GeoQuestion(q[0], q[1], this.store)
          );
        });
        return true;
      });
  }
}

export default decorate(QuestionStore, {
  filters: computed,
  geoFilters: computed,
  textFilters: computed,
  allFilters: computed,
  allGeoFilters: computed,
  filterableQuestions: computed,
  unfilterableQuestions: computed,
});
