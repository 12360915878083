import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import NewBarChart from "./charts/NewBarChart";

class Report extends React.Component {
  render() {
    const report = this.props.store.report;
    return (
      <ReportContainer>
        <h1>Rapportage</h1>
        <section>
          <h2>Respondenten</h2>
          <LabelList>
            <li>
              <label>Totaal</label>
              <p>{report.total}</p>
            </li>
            <li>
              <label>Totaal aantal vragen</label>
              <p>{report.questionTotal}</p>
            </li>
            <li>
              <label>Gemiddeld aantal antwoorden per respondent</label>
              <p>{report.averageAnswerCount.toFixed(2)}</p>
            </li>
            <li>
              <label>Totaal aantal GEO vragen</label>
              <p>{report.geoQuestionTotal}</p>
            </li>
            <li>
              <label>Gemiddeld aantal GEO antwoorden per respondent</label>
              <p>{report.averageGeoAnswerCount.toFixed(2)}</p>
            </li>
          </LabelList>
        </section>

        <section>
          <h2>Antwoord verdeling (aantal keren dat een vraag is beantwoord)</h2>
          <NewBarChart
            data={report.questionReportData}
            chart={null}
            color="#46C9A9"
            marginLeft={300}
            maxTextLength={100}
          />
        </section>

        <section>
          <h2>
            GEO antwoord verdeling (aantal keren dat een locatievraag is
            beantwoord)
          </h2>
          <NewBarChart
            data={report.geoQuestionReportData}
            chart={null}
            color="#5A97E5"
            marginLeft={300}
            maxTextLength={100}
          />
        </section>
      </ReportContainer>
    );
  }
}

const ReportContainer = styled.div`
  h1 {
    padding: 32px 32px 0 32px;
  }
  section {
    padding: 32px;
    border-bottom: 4px solid ${(props) => props.theme.gray01};
  }
`;

const LabelList = styled.ul`
  li {
    display: flex;
    padding: 10px 4px;

    &:nth-child(odd) {
      background-color: ${(props) => props.theme.gray01};
    }
    label {
      flex: 0 0 60%;
      font-weight: bold;
    }
    p {
      flex: 1 1 auto;
      text-align: right;
      padding-right: 16p;
    }
  }
`;

export default inject("store", "ui")(observer(Report));
