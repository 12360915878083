import React from "react";
import { inject, observer } from "mobx-react";
import { withParentSize } from "@vx/responsive";
import { Group } from "@vx/group";
import { Bar } from "@vx/shape";

class NewAverageChart extends React.Component {
  render() {
    const { data, parentWidth, ui } = this.props;

    const width = parentWidth;
    const height = 30;
    const margin = {
      top: 4,
      right: 0,
      bottom: 0,
      left: 0
    };

    const targetWidth = (data / 100) * width;
    const barHeight = 20;

    return (
      <svg width={width} height={height}>
        <Group key={`bars`} top={margin.top} left={margin.left}>
          <Bar
            key="bar-bg"
            x={0}
            y={0}
            height={barHeight}
            width={width}
            rx={4}
            fill="#DEE2E8"
          />
          <Bar
            key="bar-fill"
            x={0}
            y={0}
            height={barHeight}
            width={targetWidth}
            rx={4}
            fill={ui.theme.pickHeatmapColor(data, true)}
          />
          <text
            fill="black"
            textAnchor="left"
            x={Math.min(targetWidth + 5, width - 30)}
            y={barHeight / 2}
            dy=".33em"
            fontSize={9}
          >
            {data.toFixed(2)}
          </text>
        </Group>
      </svg>
    );
  }
}

export default withParentSize(inject("ui")(observer(NewAverageChart)));
