import chroma from "chroma-js";

export default class Theme {
  colorHash = {};
  colorHashCount = 0;

  chartColors = [
    "#46C9A9",
    "#DE0A00",
    "#F36B4F",
    "#F7C853",
    "#9BCD65",
    "#5A97E5",
    "#A78DE6",
    "#E583B9",
    "#4CBBE1"
  ];

  heatMap = ["#46C9A9", "#9BCD65", "#F7C853", "#F36B4F", "#E65262"];

  theme = {
    chartColors: [],
    gray01: "#EEEFF3",
    gray02: "#DFE2E7",
    gray03: "#C6C9D2",
    gray04: "#A5ACB7",
    gray05: "#626A74",
    gray06: "#414851",
    mainColor: "#4CBBE1",
    positiveColor: "#9BCD65",
    negativeColor: "#E65262",

    axisLabel: "#626A74",
    axisStroke: "#A5ACB7",
    axisTick: "#A5ACB7"
  };

  constructor() {
    this.theme.chartColors = this.chartColors;
    this.chartColors.forEach(c => {
      this.theme.chartColors.push(
        chroma(c)
          .darken(0.5)
          .hex()
      );
    });

    let h = [];
    this.heatMap.forEach((c, n) => {
      if (n < 2) {
        h.push(
          chroma(c)
            .darken()
            .hex()
        );
        h.push(c);
      } else {
        h.push(c);
        h.push(
          chroma(c)
            .darken()
            .hex()
        );
      }
    });
    this.theme.heatMap = h;
  }
  pickChartColor(n) {
    let index = n % this.theme.chartColors.length;
    return this.theme.chartColors[index];
  }

  pickHeatmapColor(d, reverse = false) {
    let n = d;
    if (n > 5) {
      n = n / 20;
    }
    n = Math.floor(n);
    if (n === 5) n = 4;
    if (reverse) {
      n = 4 - n;
    }
    return this.heatMap[n];
  }

  setKeyColor(rawString, color) {
    let s = rawString.toLowerCase();
    if (!this.colorHash[s]) {
      this.colorHashCount++;
    }
    this.colorHash[s] = color;
  }

  pickKeyColor(rawString, debug) {
    let s = rawString.toLowerCase();
    if (debug) {
      console.log(rawString, this.colorHash);
    }
    if (!this.colorHash[s]) {
      this.colorHash[s] = this.pickChartColor(this.colorHashCount);
      this.colorHashCount++;
    }
    //console.log("C", this.colorHashCount, rawString);
    return this.colorHash[s];
  }
}
