import { decorate, computed } from "mobx";

class Chart {
  chartType = "";
  question;
  cruncher;

  constructor(chartType, question, cruncher) {
    this.chartType = chartType;
    this.question = question;
    this.cruncher = cruncher;
  }

  get data() {
    switch (this.chartType) {
      case "average":
        return this.cruncher.getAverage(this.question);
      case "piechart":
      case "barchart":
      case "curve":
        let result = this.cruncher.getLabelScores(this.question);
        if (this.question.sorting !== null) {
          this.cruncher.sortLabelScores(this.question.sorting, result);
        }
        if (this.chartType === "curve") {
          if (this.question.range) {
            this.padRange(result, this.question.range);
          }
          result = result.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));
        }
        return result;
      case "barchart_compare":
        return this.cruncher.getLabelScoresCompare(this.questions);
      default:
        return 0;
    }
  }

  toggleFilterByIndex(index) {
    this.question.toggleFilter(this.data[index]);
  }

  padRange(result, range) {
    let id = result.length;
    for (let i = range[0]; i <= range[1]; i++) {
      let item = result.find((r) => r.name === i + "");

      if (!item) {
        result.push({
          id: id++,
          name: i + "",
          value: 0,
          color: "#eee"
        });
      }
    }
  }
}

export default decorate(Chart, {
  data: computed
});
