import { AxisBottom } from "@vx/axis";
import { Group } from "@vx/group";
import { withParentSize } from "@vx/responsive";
import { scaleLinear } from "@vx/scale";
import { Bar } from "@vx/shape";
import { Text } from "@vx/text";
import { inject, observer } from "mobx-react";
import React from "react";

const TICK = "#333";

class CurveChart extends React.Component {
  guessDimensions(data) {
    let maxLength = data.reduce((acc, v) => {
      if (v.name.length > acc) return v.name.length;
      return acc;
    }, 0);

    if (maxLength < 20) {
      return [50, 30];
    }

    if (maxLength < 60) {
      return [120, 50];
    }

    return [250, 80];
  }

  render() {
    const { data, marginLeft, parentWidth, chart, color } = this.props;
    const [guessedMarginLeft, guessedBarHeight] = this.guessDimensions(data);
    const margin = {
      top: 5,
      left: marginLeft ? marginLeft : guessedMarginLeft,
      right: 20,
      bottom: 40
    };
    const height = margin.top + margin.bottom + data.length * guessedBarHeight;
    const width = parentWidth;
    // accessors
    const x = (d) => d.name;
    const y = (d) => d.value;

    const xMax = width;
    const yMax = height - margin.bottom;

    const barWidth = Math.round(xMax / data.length) - 2;
    const halfBarWidth = barWidth / 2;

    // scales
    const xScale = scaleLinear({
      rangeRound: [0, xMax],
      domain: [0.5, Math.max(...data.map(x)) + 0.5]
    });
    const yScale = scaleLinear({
      rangeRound: [yMax, 0],
      domain: [0, Math.max(...data.map(y))]
    });

    let total = 0;
    let sum = 0;

    data.forEach((d) => {
      total += d.value;
      sum += d.value * d.name;
    });
    let avg = sum / total;

    const isAvgTick = (avg, value) => Math.abs(avg - value) < 0.1;

    return (
      <div>
        <svg width={width} height={height}>
          <Group top={margin.top}>
            {data.map((d, i) => {
              const letter = x(d);

              const barHeight = Math.max(yMax - yScale(y(d)), 2);
              const barX = xScale(letter) - halfBarWidth;
              const barY = yMax - barHeight;

              const labelY = Math.min(barY + 5, yMax - 20);

              return (
                <g key={`bar-${letter}`}>
                  <Bar
                    x={barX}
                    y={barY}
                    width={barWidth}
                    height={barHeight}
                    fill={color ? color : d.color}
                    onClick={(event) => {
                      if (chart) {
                        chart.question.toggleFilter(d);
                      }
                    }}
                  />
                  <Text
                    verticalAnchor="start"
                    textAnchor="middle"
                    x={barX + barWidth / 2}
                    y={labelY}
                    style={{
                      fontSize: "9px"
                    }}
                  >
                    {y(d) + ""}
                  </Text>
                </g>
              );
            })}
          </Group>
          <Group top={margin.top}>
            <Bar x={xScale(avg) - 1} y={0} height={yMax} width={2} fill={"#333"} />
          </Group>
          <AxisBottom
            top={yMax + margin.top}
            scale={xScale}
            stroke={TICK}
            tickStroke={TICK}
            tickValues={[avg, ...data.map((d) => x(d))]}
            hideAxisLine={true}
            tickLabelProps={(value, index) => {
              if (isAvgTick(avg, value)) {
                return {
                  fill: "#ff0000",
                  dy: "10px",
                  fontSize: 11,
                  textAnchor: "middle",
                  length: 12
                };
              }
              return {
                fill: TICK,
                fontSize: 11,
                textAnchor: "middle"
              };
            }}
          />
        </svg>
      </div>
    );
  }
}

export default withParentSize(inject("ui")(observer(CurveChart)));
