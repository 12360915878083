import React from "react";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { TopLeftControl } from "../../uikit/Map";
import styled from "styled-components";

export default class MapSpatialFilter extends React.Component {
  componentDidMount() {
    const { map } = this.props;
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    });
    map.addControl(this.draw, "top-left");

    map.on("draw.create", this.createArea);
    map.on("draw.delete", this.deleteArea);
    map.on("draw.update", this.updateArea);

    this.draw.changeMode("draw_polygon");
  }

  componentWillUnmount() {
    const { map } = this.props;
    if (!map || !map.getStyle()) {
      console.log("Resturtnsd");
      return;
    }

    setTimeout(() => {
      map.off("draw.create", this.createArea);
      map.off("draw.delete", this.deleteArea);
      map.off("draw.update", this.updateArea);
      map.removeControl(this.draw);
      this.draw = null;
    }, 100);
  }

  createArea = e => {
    console.log("create");
    var data = this.draw.getAll();
    this.props.mapView.spatialFilter.update(data);
  };

  updateArea = e => {};

  deleteArea = e => {
    console.log("DElete");
  };

  render() {
    return (
      <TopLeftControl>
        <CancelButton
          onClick={() => {
            this.props.mapView.spatialFilter = null;
          }}
        >
          Cancel
        </CancelButton>
      </TopLeftControl>
    );
  }
}

const CancelButton = styled.button`
  margin-left: 40px;
  border-radius: 8px;
  background-color: ${props => props.theme.gray04};
  color: #333;
  padding: 8px 16px;

  &:hover {
    color: #fff;
  }
`;
