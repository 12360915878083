import { Map } from "core-js";

export default class DataCruncher {
  store;
  initials = new Map();

  constructor(store) {
    this.store = store;
  }

  getAverage(question) {
    let answers = this.store.filteredAnswers.filter(
      a => a.questionId === question.id
    );
    if (answers.length === 0) return 0;
    return answers.reduce((acc, cur) => acc + cur.value, 0) / answers.length;
  }

  //with filtering some values might disappear, we want to show these as value 0 so storing it here.
  getInitial(questionId) {
    let initial = this.initials.get(questionId);
    if (initial) {
      Object.keys(initial).forEach(key => (initial[key] = 0));
    }
    return initial;
  }

  getLabelScores(question) {
    //TODO: inefficient, looping twice.

    let answers = this.store.filteredAnswers.filter(
      a => a.questionId === question.id
    );

    if (answers.length === 0) {
      return [
        {
          id: "novalue",
          name: "No data",
          color: this.store.theme.pickKeyColor("no data")
        }
      ];
    }

    let result = this.getInitial(question.id);

    if (question.format === "json") {
      if (!result) {
        result = {};

        Object.keys(answers[0].value).forEach(key => (result[key] = 0));
        this.initials.set(question.id, result);
      }

      answers.forEach(a => {
        Object.entries(a.value).forEach(entry => {
          if (entry[1]) {
            if (question.scoring) {
              result[entry[0]] = result[entry[0]] + entry[1];
            } else {
              if (result[entry[0]]) {
                result[entry[0]]++;
              } else {
                result[entry[0]] = 1;
              }
            }
          }
        });
      });
    } else {
      let storeInitial = false;
      if (!result) {
        storeInitial = true;
        result = {};
      }

      answers.forEach(a => {
        if (result[a.value] === undefined) {
          result[a.value] = 1;
        } else {
          result[a.value]++;
        }
      });
      if (storeInitial) {
        this.initials.set(question.id, result);
      }
    }
    if (question.id === 50) {
      console.log(result);
    }
    let endResult = Object.entries(result).map((entry, index) => ({
      id: index,
      name: entry[0],
      value: entry[1],
      color: this.store.theme.pickKeyColor(entry[0])
    }));

    if (question.sorting) {
      this.sortLabelScores(question.sorting, endResult);
      endResult = endResult.map((d, n) => ({
        ...d,
        id: n
      }));
    }
    return endResult;
  }

  getLabelScoresCompare(questions) {
    let result = [];
    questions.forEach((q, index) => {
      let d = this.getLabelScores(q);
      for (let i = 0, n = d.length; i < n; i++) {
        if (i > result.length - 1) {
          let o = {
            name: d[i].name
          };
          o["value" + (index + 1)] = d[i].value;
          result.push(o);
        } else {
          result[i]["value" + (index + 1)] = d[i].value;
        }
      }
    });
    return result;
  }

  sortLabelScores(sortType, result) {
    switch (sortType) {
      //built in sorting
      case "agerange":
        result.sort(this.ageRangeSort);
        break;
      default:
        const sc = this.store.configStore.getSortScheme(sortType);
        if (sc) {
          this.sortSchemeSort(sc, result);
        }
    }
  }

  ageRangeSort(a, b) {
    if (a.name.indexOf("<") === 0) return 1;
    if (b.name.indexOf("<") === 0) return -1;
    if (a.name.indexOf("+") > 0) return -1;
    if (b.name.indexOf("+") > 0) return 1;
    if (a.name.indexOf(">") >= 0) return -1;
    if (b.name.indexOf(">") >= 0) return 1;

    let na = parseInt(a.name.split("-")[0], 10);
    let nb = parseInt(b.name.split("-")[0], 10);

    return nb - na;
  }

  sortSchemeSort(sc, items) {
    items.sort((a, b) => {
      let aI = sc.findIndex(l => l === a.name.toLowerCase());
      let bI = sc.findIndex(l => l === b.name.toLowerCase());
      return bI - aI;
    });
  }
}
