import React from "react";
import { observer } from "mobx-react";

import { ChapterNavContainer } from "../uikit/Frame";
import { ChapterNav, Tab } from "../uikit/ChapterNav";

class Chapter extends React.Component {
  render() {
    const { chapters, activeChapter, setActive } = this.props;
    return (
      <ChapterNavContainer>
        <ChapterNav>
          {chapters.map(chapter => (
            <Tab
              key={chapter.key}
              className={
                activeChapter && activeChapter.key === chapter.key
                  ? "active"
                  : "in-active"
              }
              onClick={e => {
                e.preventDefault();
                setActive(chapter);
              }}
            >
              {chapter.title}
            </Tab>
          ))}
        </ChapterNav>
      </ChapterNavContainer>
    );
  }
}

export default observer(Chapter);
