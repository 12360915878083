import * as React from "react";
import * as d3 from "d3";

export default function Arc({
  arc,
  pie,
  i,
  color,
  maxValue,
  opacity,
  centroidX,
  centroidY,
  onClick,
  active,
  isPercent
}) {
  const [curArc, _curArc] = React.useState(arc);
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    const tgt = ref.current;
    if (tgt) {
      d3.select(tgt)
        .transition()
        .duration(300)

        .attrTween("d", arcTween(curArc, arc, pie.path))
        .on("end", () => {
          _curArc(arc);
        });
    }
    return () => {
      d3.select(tgt).transition();
    };
  }, [arc]);

  return (
    <g>
      <path
        ref={ref}
        onClick={onClick}
        d={pie.path(arc)}
        fill={color}
        fillOpacity={maxValue === 0 ? 0 : opacity}
      />
      <text
        fill={active ? "#333" : "#fff"}
        textAnchor="middle"
        x={centroidX}
        y={centroidY}
        dy=".33em"
        fontSize={10}
        fontWeight={active ? "bold" : "normal"}
      >
        {arc.data.value !== 0 ? `${arc.data.value}${isPercent ? "%" : ""}` : ""}
      </text>
    </g>
  );
}

function arcTween(oldData, newData, arc) {
  const copy = { ...oldData };
  return function() {
    const interpolateStartAngle = d3.interpolate(
        oldData.startAngle,
        newData.startAngle
      ),
      interpolateEndAngle = d3.interpolate(oldData.endAngle, newData.endAngle);

    return function(t) {
      copy.startAngle = interpolateStartAngle(t);
      copy.endAngle = interpolateEndAngle(t);
      return arc(copy);
    };
  };
}
