import React from "react";
import styled from "styled-components";

export const Toggle = ({ label, children }) => (
  <ToggleContainer>
    <label>{label}</label>
    <ul>{children}</ul>
  </ToggleContainer>
);

export const ToggleOption = props => (
  <ToggleOptionContainer
    className={props.active ? "active" : "inactive"}
    onClick={props.onClick}
    title={props.title}
  >
    {props.children}
  </ToggleOptionContainer>
);

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  label {
    flex: 1 1 auto;
    font-size: 11px;
    padding: 0 10px 2px 0;
    margin: 0;
    word-wrap: break-word;
  }
  ul {
    flex: 0 0 64px;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    height: 32px;
    border: 1px solid ${props => props.theme.gray02};
  }
`;

export const ToggleOptionContainer = styled.li`
  height: 32px;
  flex: 1 1 auto;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;

  background-color: ${props => props.theme.gray01};
  &.active {
    background-color: #fff;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: ${props => props.theme.gray05};
  }
`;
