import * as React from "react";

//Chart libs
import { Group } from "@vx/group";
import { Pie } from "@vx/shape";
import Arc from "./Arc";

const pieSorter = (a, b) => a.id - b.id;

export default function PieChart({
  width,
  data,
  optionClick,
  chart,
  isPercent = false
}) {
  const margin = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };

  const pieWidth = width - margin.left - margin.right;

  const height = pieWidth;
  const radius = Math.floor(pieWidth / 2);
  //const opacityStep = 0.8 / data.length;

  let maxValue = data.reduce((acc, d) => {
    if (d.value > acc) return d.value;
    return acc;
  }, 0);
  let itemCount = data.length;

  if (maxValue === 0) {
    data = data.map(d => ({
      id: d.id,
      name: d.name,
      value: itemCount
    }));
  }
  return (
    <svg width={width} height={height}>
      <Group top={radius + margin.top} left={margin.left + radius}>
        <Pie
          data={data}
          pieValue={d => d.value}
          outerRadius={radius}
          innerRadius={6}
          cornerRadius={0}
          padAngle={0}
          pieSort={pieSorter}
        >
          {pie => {
            return pie.arcs.map((arc, i) => {
              const opacity = 1; // - i * opacityStep;
              const [centroidX, centroidY] = pie.path.centroid(arc);
              const active = chart.question.isActive(arc.data);

              return (
                <Arc
                  key={`${arc.data.name}-${i}`}
                  i={i}
                  arc={arc}
                  pie={pie}
                  centroidX={centroidX}
                  centroidY={centroidY}
                  opacity={opacity}
                  maxValue={maxValue}
                  color={arc.data.color}
                  active={active}
                  isPercent={isPercent}
                  onClick={() => {
                    optionClick(arc.data);
                  }}
                />
              );
            });
          }}
        </Pie>
      </Group>
    </svg>
  );
}
