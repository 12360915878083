import styled from "styled-components";

export const AppContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  align-items: stretch;
`;

export const Content = styled.div`
  flex: 1;
`;
export const NavContainer = styled.nav`
  width: 100%;
  flex: 0 0 50px;
`;

export const ChapterNavContainer = styled.nav`
  flex: 0 0 50px;
`;

export const Dash = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  overflow: hidden;
`;

export const MapContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

export const ContentContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`;
