import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";

class OpenQuestionView extends React.Component {
  render() {
    const { store, ids } = this.props;

    let questions = ids.map((id) => store.questionStore.getQuestion(id));
    let p = Math.floor(100 / ids.length);
    return (
      <Container>
        <header>
          {questions.map((question) => (
            <section key={question.id} style={{ flexBasis: `${p}%` }}>
              <h2>{question.title}</h2>
            </section>
          ))}
        </header>
        <main>
          {questions.map((question) => (
            <section key={question.id} style={{ flexBasis: `${p}%` }}>
              <div>
                {question.filteredAnswers.map((a, n) => (
                  <AnswerContainer key={n} dangerouslySetInnerHTML={{ __html: a.value.replace(/\\n/g, "<br />") }} />
                ))}
              </div>
            </section>
          ))}
        </main>
      </Container>
    );
  }
}

export default inject("store")(observer(OpenQuestionView));

const Container = styled.div`
  background-color: #fff;
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  header {
    flex: 0 0 auto;
    display: flex;
  }

  main {
    flex: 1 1 auto;
    overflow: scroll;
    display: flex;
  }

  section {
    padding: 16px;
    background-color: #fff;
    max-width: 700px;
    &:last-child {
      border-right: 0;
    }
  }
`;

const AnswerContainer = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eee;
`;
