import { decorate, observable } from "mobx";
class SpatialFilter {
  layer = "";
  active = false;

  constructor(layer) {
    this.layer = layer;
  }

  update(data) {
    this.active = true;
    this.layer.geoQuestion.removeFilters();
    this.layer.geoQuestion.addFilter(data);
  }
}

export default decorate(SpatialFilter, {
  active: observable
});
