import React from "react";
import styled from "styled-components";
import { XIcon } from "./Icons";

export const FilterList = styled.ul`
  display: flex;
  width: 100%;
`;

export const FilterItem = ({ children, onClose, onToggle, className }) => (
  <FilterItemContainer className={className}>
    <label onClick={onToggle}>{children}</label>
    <button onClick={onClose}>
      <XIcon />
    </button>
  </FilterItemContainer>
);

export const FilterItemContainer = styled.li`
  flex: 0 0 auto;
  color: #fff;
  display: flex;
  align-items: stretch;
  border-radius: 4px;
  justify-content: space-between;
  background-color: ${props => props.theme.gray05};
  overflow: hidden;
  margin-right: 6px;
  label {
    flex: 1 1 auto;
    white-space: nowrap;
    margin: 0;
    padding: 0 6px 0 6px;
    border-right: 1px solid ${props => props.theme.gray06};
    display: flex;
    align-items: center;

    &:hover {
      color: ${props => props.theme.mainColor};
    }

    span {
      width: 6px;
      height: 6px;
      border: 1px solid #eee;
      border-radius: 50%;
      display: inline-block;
      margin-right: 6px;
    }
  }

  button {
    border: 0;
    flex: 0 0 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    svg {
      fill: ${props => props.theme.gray04};
      width: 10px;
      height: 10px;
    }

    &:hover {
      background-color: ${props => props.theme.negativeColor};
      svg {
        fill: #fff;
      }
    }
  }

  &.in-active {
    background-color: ${props => props.theme.gray06};
    label {
      color: ${props => props.theme.gray04};
    }
  }
`;
