import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "1bf608fc-b2df-4309-a481-e8b6f09f2310"
    };
  }

  componentDidMount() {
    let qs = this.props.store.questionStore.filterableQuestions;
    if (qs.length) {
      this.setState({
        id: qs[0].id
      });
    }
  }

  render() {
    const { store, ui } = this.props;
    return (
      <Container>
        <select
          value={this.state.id}
          onChange={(e) => {
            this.setState({
              id: e.currentTarget.value
            });
          }}
        >
          {store.questionStore.filterableQuestions.map((q) => (
            <option key={q.id} value={q.id}>
              {q.title}
            </option>
          ))}
        </select>
        <br />
        <br />
        {Array.from(store.users.values()).map((user) => {
          return (
            <UserItem key={user.id} className={user.active ? "active" : "idle"}>
              <h5>{user.id}</h5>
              <p>{user.answerValue(this.state.id)}</p>
              <button
                onClick={() => {
                  store.setActiveUser(user);
                  ui.modal = null;
                }}
              >
                {user.active ? "deactiveer" : "activeer"}
              </button>
            </UserItem>
          );
        })}
      </Container>
    );
  }
}

export default inject("store", "ui")(observer(UserList));

const Container = styled.div`
  padding: 32px;
`;

const UserItem = styled.div`
  border-bottom: 1px solid #414851;
  padding-bottom: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    flex: 0 0 120px;
    font-weight: bold;
  }
  p {
    flex: 1 1 auto;
    text-align: left;
  }
  button {
    flex: 0 0 120px;
    border: 0;
    border-radius: 3px;
    padding: 8px 16px;
    background-color: #5a97e5;
    color: #fff;
  }
  &.active {
    h5 {
      color: #9bcc66;
    }
    button {
      background-color: #9bcc66;
    }
  }
`;
