import React from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";

class Modal extends React.Component {
  getChildren() {
    const { ui } = this.props;
    if (ui.modal && ui.modal.title) {
      return ui.modal.children;
    }
    return ui.modal;
  }

  closeAction = e => {
    this.props.ui.modal = null;
  };

  render() {
    const { ui } = this.props;
    return (
      <ModalContainer className={ui.modal.small ? "small" : "default"}>
        {ui.modal && ui.modal.title ? (
          <ModalHeader>
            <h2>{ui.modal.title}</h2>
            <button onClick={this.closeAction}>X</button>
          </ModalHeader>
        ) : (
          ""
        )}
        <ModalContent>{this.getChildren()}</ModalContent>
      </ModalContainer>
    );
  }
}

export default inject("ui")(observer(Modal));

const ModalContent = styled.div`
  width: 100%;
  max-width: 800px;
  height: 80vh;
  overflow-y: scroll;

  background-color: #fff;
`;

export const ModalHeader = styled.div`
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.gray02};
  display: flex;
  align-items: center;
  h2 {
    color: #333;
    margin: 0;
    flex: 1 1 auto;
    padding: 8px 32px;
  }

  button {
    flex: 0 0 80px;
    height: 60px;
    border: 0;
    border-left: 1px solid ${props => props.theme.gray02};
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: ${props => props.theme.gray06};
    }
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  &.small {
    ${ModalHeader} {
      max-width: 400px;
    }

    ${ModalContent} {
      max-width: 400px;
      height: 40vh;
    }
  }
`;
