import styled from "styled-components";

export default styled.div``;

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Legend = styled.ul`
  flex: 0 0 50%;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    div {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      flex: 0 0 10px;
    }

    a {
      margin-left: 10px;
      flex: 1 1 auto;
      text-decoration: none;
      color: ${props => props.theme.axisLabel};
      font-size: 12px;
      span {
        font-size: 10px;
        color: ${props => props.theme.gray03};
      }

      &.active {
        color: #333;
        font-weight: bold;
      }
    }
  }
`;

export const Chart = styled.div`
  flex: 0 0 50%;
`;

export const ChartItemContainer = styled.div`
  h2 {
    font-size: 16px;
    span {
      font-size: 10px;
      font-weight: lighter;
      color: ${props => props.theme.gray03};
    }
  }
`;
