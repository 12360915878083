import React from "react";
import { Dash } from "../uikit/Frame";

import Sidebar from "./Sidebar";
import InfoMap from "./InfoMap";
import OpenQuestionView from "./OpenQuestionView";
export default function ChapterView({ chapter, store, active }) {
  if (!active) return null;
  return (
    <Dash>
      <Sidebar key="sidebar" activeChapter={chapter} full={!chapter.hasMap && !chapter.hasOpen} />
      {chapter.hasMap && <InfoMap key="infomap" store={store} />}
      {chapter.hasOpen && <OpenQuestionView ids={chapter.openQuestionIds} />}
    </Dash>
  );
}
