import React from "react";
import { observer, inject } from "mobx-react";

import NewAverageChart from "./charts/NewAverageChart";
import NewPieChart from "./charts/NewPieChart";
import NewBarChart from "./charts/NewBarChart";
import OtherAnswers from "./OtherAnswers";
import { ChartItemContainer } from "../uikit/Chart";
import CurveChart from "./charts/CurveChart";

class ChartItem extends React.Component {
  renderChart(chart) {
    switch (chart.chartType) {
      case "curve":
        return <CurveChart data={chart.data} chart={chart} />;
      case "average":
        return <NewAverageChart data={chart.data} chart={chart} />;
      case "piechart":
        return <NewPieChart data={chart.data} chart={chart} />;
      case "barchart":
        return <NewBarChart data={chart.data} chart={chart} />;
      default:
        return <p>None</p>;
    }
  }

  render() {
    //console.log(this.props);
    const { title, question } = this.props;

    return (
      <ChartItemContainer>
        <h2>
          {title ? title : question.title}
          <span>({question.filteredAnswers.length})</span>
        </h2>
        {question.compact ? <Compact question={question} label={question.compact} /> : this.renderChart(question.chart)}
        {question.other !== "" && <OtherAnswers question={question} />}
      </ChartItemContainer>
    );
  }
}

export default inject("store")(observer(ChartItem));

/*
<ul>
          {question.chartList.map(cl => (
            <li key={cl.chartType}>
              <a
                href="#0"
                onClick={e => {
                  e.preventDefault();
                  question.activeChartType = cl.chartType;
                }}
              >
                {cl.chartType}
              </a>
            </li>
          ))}
        </ul>
        */

class _Compact extends React.Component {
  constructor() {
    super();
    this.state = {
      d: null
    };
  }
  render() {
    const { question, label } = this.props;
    const { d } = this.state;
    return (
      <div>
        <select
          value={d ? d.id : ""}
          onChange={(e) => {
            let v = e.currentTarget.value;
            if (!v) {
              question.clearFilters();
              this.setState({ d: null });
            } else {
              let _d = question.chart.data.find((d) => d.id == e.currentTarget.value);
              if (_d) {
                this.setState({ d: _d });
                question.clearFilters();
                question.toggleFilter(_d);
              }
            }
          }}
        >
          <option value="">{label}</option>
          {question.chart.data.map((d) => {
            return (
              <option key={d.id} value={d.id}>
                {d.name} ({d.value})
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

const Compact = inject("store")(observer(_Compact));
