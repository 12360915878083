import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "1bf608fc-b2df-4309-a481-e8b6f09f2310"
    };
  }

  componentDidMount() {
    let qs = this.props.store.questionStore.filterableQuestions;
    if (qs.length) {
      this.setState({
        id: qs[0].id
      });
    }
  }

  render() {
    const { store, ui } = this.props;
    return (
      <Container>
        <h2>Je kijkt naar een interactief Dagen.App Dashboard.</h2>

        <p>
          Scroll en zoom op de kaart om naar de responsdata te gaan. Klik vervolgens op een punt op de kaart en zie alle
          ingevulde details.
        </p>

        <h4>Data filteren?</h4>
        <p>
          Klik dan op een kleur in de legenda links (bijvoorbeeld een leeftijdscategorie). De data op de kaart verandert
          direct mee. Voeg met één klik het volgende filter toe of klik nogmaals om het filter weer op te heffen.
        </p>

        <h4>Snel een warmtekaart zien?</h4>
        <p>
          Klik dan op één van de thermometer-icoontjes in de legenda rechts op de kaart. Je kunt hier ook eenvoudig de
          achtergrondkaart wijzigen of via het vergrootglas zoeken op specifieke antwoorden.
        </p>

        <h4>Hou je meer van diagrammen?</h4>
        <p>
          Klik dan rechtsboven op de knop 'Alle vragen' en je komt in het tweede tabblad. Ook hier kun je weer filteren
          en zelfs meerdere filters tegelijk gebruiken om uit te komen bij je gewenste dashboard-data.
        </p>
      </Container>
    );
  }
}

export default inject("store", "ui")(observer(Info));

const Container = styled.div`
  padding: 32px;

  h4 {
    margin-top: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    line-height: 1.3em;
  }
`;
