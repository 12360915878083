import styled from "styled-components";

export const GridList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.gray01};
`;

export const GridListItem = styled.div`
  flex: 0 0 32%;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fff;
  max-width: 49%;
`;
