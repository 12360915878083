import React from "react";
import styled from "styled-components";
import { BaseButton, PrimaryButton, ButtonGroup } from "../uikit/Buttons";
import { observer, inject } from "mobx-react";

class TextFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
  }

  render() {
    const { layer, ui } = this.props;
    const { text } = this.state;

    return (
      <Container>
        <h2>{layer.title}</h2>
        <Input
          type="text"
          value={text}
          onChange={e => {
            this.setState({ text: e.currentTarget.value });
          }}
        />

        <ButtonGroup>
          <PrimaryButton
            onClick={() => {
              layer.geoQuestion.addTextFilter(text);
              ui.modal = null;
            }}
          >
            Voeg filter toe
          </PrimaryButton>
          <BaseButton
            onClick={() => {
              ui.modal = null;
            }}
          >
            Annuleren
          </BaseButton>
        </ButtonGroup>
      </Container>
    );
  }
}
export default inject("store", "ui")(observer(TextFilter));

const Container = styled.div`
  padding: 32px;
`;

const Input = styled.input`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #333;
  font-size: 16px;
  padding: 4px 8px;
`;
