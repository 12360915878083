import { decorate, observable } from "mobx";
import Theme from "./Theme";

class UIStore {
  content = null;

  modal = null;

  theme = new Theme();
}

export default decorate(UIStore, {
  content: observable,
  modal: observable
});
