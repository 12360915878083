import styled from "styled-components";

export const BaseButton = styled.button`
  border-radius: 6px;
  border: 0;
  background-color: #788f92;
  color: #fff;
  text-align: center;
  font-size: 16px;
  padding: 8px 16px;

  &:hover {
    background-color: #5d6f71;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: #4cbce1;

  &:hover {
    background-color: #83cbe4;
  }
`;

export const ButtonGroup = styled.div`
  margin: 16px 0 0 0;
  display: flex;
  width: 100%;
  justify-content: space-between;

  button {
    margin: 0 8px;
    flex: 1 1 auto;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
