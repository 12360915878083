import styled from "styled-components";

export const ChapterNav = styled.div`
  background-color: ${props => props.theme.gray02};
  border-bottom: 1px solid ${props => props.theme.gray01};
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
`;

export const Tab = styled.button`
  height: 50px;
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  align-items: center;
  color: ${props => props.theme.gray05};
  background-color: ${props => props.theme.gray02};
  border: 0;
  font-size: 16px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }

  &.in-active {
    border-right: 1px solid ${props => props.theme.gray03};
    &:last-child {
      border-left: 0;
    }
    background-image: linear-gradient(
      to bottom,
      transparent,
      transparent 96%,
      rgba(0, 0, 0, 0.2)
    );
    transition: all 300ms ease-in-out;
    &:hover {
      background-color: #fff;
      background-image: linear-gradient(
        to bottom,
        transparent,
        transparent 100%
      );
    }
  }

  &.active {
    font-weight: bold;
    background-color: #fff;
    border-top: 3px solid ${props => props.theme.mainColor};
  }
`;
