import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";

class ImageWithPopup extends React.Component {
  render() {
    const { src, ui } = this.props;

    return (
      <ImageContainer
        className="image-container"
        src={src}
        onClick={() => {
          ui.modal = {
            title: `Image`,
            children: <Image src={src} />
          };
        }}
      />
    );
  }
}

export default inject("ui")(observer(ImageWithPopup));

const ImageContainer = styled.div`
  background: #dfe2e7 url("${(props) => props.src}") no-repeat center center;
  background-size: contain;
  width: 100%;
  height: 200px;
`;

const Image = styled.img`
  max-width: 100%;
`;
