import { decorate, observable, computed } from "mobx";
import Filter from "./Filter";

class GeoQuestion {
  store;
  id;
  key;
  altKeys = [];
  answers = [];
  filters = [];
  format;

  _title = null;

  constructor(key, conf, store) {
    this.key = key;
    this.id = conf.id + "";
    this.store = store;
    this.format = conf.format;
    if (conf.merge) {
      this.altKeys = conf.merge;
    }
    if (conf.title) this._title = conf.title;
  }

  matchesKey(key) {
    if (this.key === key) return true;
    if (this.altKeys.length) {
      return this.altKeys.includes(key);
    }
    return false;
  }

  get title() {
    if (this._title) return this._title;
    return this.key;
  }

  get color() {
    return this.store.theme.pickKeyColor(this.key);
  }

  addAnswer(a) {
    this.answers.push(a);
  }

  get filteredAnswers() {
    return this.store.filteredAnswers.filter((a) => a.questionId === this.id);
  }

  get activeFilters() {
    return this.filters.filter((f) => f.active);
  }

  get textFilters() {
    return this.activeFilters.filter((f) => f.type === "text");
  }

  addFilter(shape) {
    this.filters.push(new Filter(this, shape, "geo"));
  }

  addTextFilter(s) {
    this.filters.push(new Filter(this, s.toLowerCase(), "text"));
  }

  isActive(d) {
    return this.hasFilter(d);
  }

  hasFilter(d) {
    return this.activeFilters.length > 0;
  }

  removeFilter(f) {
    this.filters.remove(f);
  }

  removeFilters() {
    this.filters = [];
  }
}

export default decorate(GeoQuestion, {
  title: computed,
  filters: observable,
  filteredAnswers: computed,
  textFilters: computed,
  activeFilters: computed,
  color: computed
});
