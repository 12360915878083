import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { ThemeProvider } from "styled-components";
import { AppContainer, ContentContainer } from "./uikit/Frame";
import MainNav from "./components/MainNav";
import ChapterNav from "./components/ChapterNav";
import ChapterView from "./components/ChapterView";
import Modal from "./uikit/Modal";
import { BigBadLoader } from "./uikit/loaders";

import "./css/global.css";
import "./css/typography.css";

class App extends Component {
  componentDidMount() {
    this.props.store.load();
  }

  render() {
    const { store, ui } = this.props;
    if (store.loading) {
      return <BigBadLoader>Preparing data...</BigBadLoader>;
    }

    return (
      <ThemeProvider theme={store.theme.theme}>
        <AppContainer>
          <MainNav store={store} />
          {ui.content !== null ? (
            <ContentContainer>{ui.content}</ContentContainer>
          ) : (
            <>
              {store.chapters.length && store.chapters.length > 1 && (
                <ChapterNav
                  key="chapternav"
                  chapters={store.chapters}
                  activeChapter={store.activeChapter}
                  setActive={(chapter) => store.setActiveChapter(chapter)}
                />
              )}
              {store.chapters.map((chapter) => (
                <ChapterView
                  key={chapter.key}
                  chapter={chapter}
                  active={chapter.key === store.activeChapter.key}
                  store={store}
                />
              ))}
            </>
          )}
          {ui.modal !== null && <Modal />}
        </AppContainer>
      </ThemeProvider>
    );
  }
}

export default inject("store", "ui")(observer(App));
