import styled, { keyframes } from "styled-components";
import React from "react";

export function BigBadLoader({ children }) {
  return (
    <BigBadLoaderContainer>
      <Loading />
      <div className="msg">{children}</div>
    </BigBadLoaderContainer>
  );
}

const BigBadLoaderContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
  height: 100vh;
  background-color: #414851;

  .msg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 220px;
    height: 220px;
    margin: 80px 0 0 -110px;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

export const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  background: #c33764;

  background: linear-gradient(to bottom, #1d2671, #c33764);

  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 10px solid #4cbbe1;
  &:after {
    content: "";
    background: trasparent;
    width: 140%;
    height: 140%;
    position: absolute;
    border-radius: 100%;
    top: -20%;
    left: -20%;
    opacity: 0.7;
    box-shadow: rgba(255, 255, 255, 0.6) -4px -5px 3px -3px;
    animation: ${rotate} 2s infinite linear;
  }
`;
