import { decorate, observable, computed } from "mobx";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

class Filter {
  question;
  d;
  _title = "";
  active = true;
  type = "answer";

  constructor(question, d, type = "answer", title = "") {
    this.question = question;
    this.d = d;
    this.type = type;
    this._title = title;
  }

  get title() {
    if (this.type === "answer") {
      return this.d.name;
    } else if (this.type === "text") {
      return this.d;
    } else if (this.type === "user") {
      return this._title;
    }
    return this.question.key;
  }

  remove() {
    if (this.type === "answer") {
      this.question.removeFilter(this.d);
    } else {
      this.question.removeFilter(this);
    }
  }

  toggle() {
    this.active = !this.active;
  }

  matchesAnswer(v) {
    if (this.type === "user") {
      return this.d.includes(v.userId);
    }
    if (this.question.format === "json") {
      return v[this.d.name];
    }

    return this.d.name === v;
  }

  matchesData(d) {
    return this.d.name === d.name;
  }

  matchesShape(v) {
    let point = v.geometry.coordinates;

    return booleanPointInPolygon(point, this.d.features[0]);
  }

  matchesText(v) {
    let index = v.indexOf(this.d);
    let result = index > -1;
    // if (result) {
    //   console.log(index, v, " === ", this.d);
    // }
    return result;
  }
}

export default decorate(Filter, {
  active: observable,
  title: computed
});
