import React from "react";
import { inject, observer } from "mobx-react";

//Chart libs

import Pie from "./element/Pie";
import { withParentSize } from "@vx/responsive";
import { ChartContainer, Legend } from "../../uikit/Chart";

class NewPieChart extends React.Component {
  render() {
    const { data, chart, parentWidth } = this.props;
    if (data.length && data[0].id === "novalue") return null;
    const width = parentWidth / 2;

    let total = data.reduce((acc, cur) => {
      return acc + cur.value;
    }, 0);

    let dataPerc = data.map((d) => {
      return {
        ...d,
        value: Math.round((d.value / total) * 100)
      };
    });

    return (
      <ChartContainer>
        <Legend>
          {data.map((d) => (
            <li key={d.name}>
              <div
                style={{
                  backgroundColor: d.color
                }}
              />
              <a
                href="#0"
                className={chart.question.isActive(d) ? "active" : "inactive"}
                onClick={(e) => {
                  e.preventDefault();
                  chart.question.toggleFilter(d);
                }}
              >
                {d.name} <span>({d.value})</span>
              </a>
            </li>
          ))}
        </Legend>
        <Pie
          width={width}
          data={dataPerc}
          isPercent={true}
          chart={chart}
          optionClick={(d) => {
            chart.question.toggleFilter(d);
          }}
        />
      </ChartContainer>
    );
  }
}

export default withParentSize(inject("ui")(observer(NewPieChart)));
